<template>
    <pc-select
        v-model="inputValue"
        v-bind="$attrs"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        :items="items"
    />
</template>

<script lang="ts">
import {Request, ResourceCollection} from "@/lib/apollo";
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({
    inheritAttrs: false,
})
export default class PCCountry extends Vue {
    @Prop({ default: '' })
    readonly value: string;

    inputValue = '';

    countries: Array<any> = [];

    created () {
        Request.make('/api/countries?_order%5Bpos%5D=asc&_order%5Bname%5D=asc', Request.GET, {}, true).then((res: any) => {
            const result = new ResourceCollection(res);

            this.countries = result.items;
        });
    }

    get items() {
        const output = [];
        for (let i in this.countries) {
            output.push({ value: this.countries[i].id, label: this.countries[i].name });
        }
        return output;
    }

    @Watch('value')
    onValueChange(val: string) {
        this.inputValue = val;
    }

    @Watch('inputValue')
    onInputChange(val: string) {
        this.$emit('input', val);
        this.$emit('change', val);
    }
}
</script>
