<template>
    <div class="mb-3" :class="{ 'is-invalid': hasError }">
        <label class="form-label" v-if="label.length" :for="labelID">{{ $t(label) }}<template v-if="required !== undefined"> *</template>:</label>
        <select class="form-select" :class="{ 'is-invalid': hasError }" v-bind="$attrs" :id="labelID" v-model="inputValue" v-on:change="$emit('input', value)" :required="required" @focus="$emit('focus')" @blur="$emit('blur')">
            <option v-if="placeholder.length">{{ $t(placeholder) }}</option>
            <template v-for="item in items">
                <optgroup v-if="topLevelAsOptgroup && item.depth === 0" :key="item.value" :label="$te(item.label) ? $t(item.label) : item.label" />
                <option v-else :key="item.value" :value="item[itemValue]" :selected="inputValue === item[itemValue]">
                    <template v-if="typeof item.depth === 'number' && item.depth > 0">
                        {{ '&nbsp;&nbsp;'.repeat(item.depth) }}
                    </template>
                    <slot name="label" :item="item">
                        {{ $te(item[itemLabel]) ? $t(item[itemLabel]) : item[itemLabel] }}
                    </slot>
                </option>
            </template>
        </select>
        <div class="invalid-feedback" v-if="feedback.length">
            <template v-for="(msg, i) in feedback">
                <span :key="i">{{ msg }}<br /></span>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class PCSelect extends Vue {
    @Prop({ default: '' })
    readonly value: string;

    @Prop({ default: '' })
    readonly label: string;

    @Prop({ default: false })
    readonly hasError: boolean;

    @Prop({ default: () => [] })
    readonly feedback: Array<string>;

    @Prop({ default: '' })
    readonly placeholder: string;

    @Prop({ default: [] })
    readonly items: Array<any>;

    @Prop({ default: false })
    readonly topLevelAsOptgroup: boolean;

    @Prop()
    readonly required: string;

    @Prop({ default: 'label' })
    readonly itemLabel: string;

    @Prop({ default: 'value' })
    readonly itemValue: string;

    inputValue = '';
    labelID = '';

    mounted () {
        this.inputValue = this.value;
        this.labelID = 'pc-select-' + Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 5)
    }

    @Watch('value')
    onValueChange(val: string) {
        this.inputValue = val;
    }

    @Watch('inputValue')
    onInputChange(val: string) {
        this.$emit('input', val);
        this.$emit('change', val);
    }
}
</script>
